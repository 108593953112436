<template>
    <div class="row-item" v-if="loaded">
        <div class="column-space-log" v-if="extraInfo.includes('space-log')"></div>

        <div class="column-icon" v-if="currentTask.type === 'task' && currentTask.frequency != null"><icon-repeat /></div>
        <div class="column-check-task" v-else>
            <div class="checkbox-tbf-special" :class="{active: currentTask.done}" @click="toggleCheck(currentTask)">
                <icon-check />
            </div>
        </div>
        <div class="column-input" :class="{done: currentTask.done}">
            <InputName :placeholder="placeholderType" :data="currentTask.name" :key="`input-name-${index}-${keyResultId ? keyResultId : 0}`" @updateName="generateData($event, currentTask)"/>
        </div>
        
        <div class="column-input-date" v-if="extraInfo.includes('date') && fromStep != 'today_planning'">
            <div class="deadline-calendar" :class="{bottom: index < 3, top: index >= 3}">
                <template v-if="(currentTask.type === 'task' && currentTask.frequency != null) || extraInfo.includes('key_result')">
                    <InputCalendarPicker :data="currentTask.deadline" @updateDate="generateData($event, currentTask, 'deadline')" :key="`input-calendar-${index}-${keyResultId ? keyResultId : 0}`"/>
                </template>
                <template v-else>
                    <InputCalendarPicker :data="currentTask.date" @updateDate="generateData($event, currentTask)" :key="`input-calendar-${index}-${keyResultId ? keyResultId : 0}`"/>
                </template>
            </div>
        </div>
        <div class="column-input-flag" v-if="extraInfo.includes('priority')">
            <InputPriorityCheck :data="currentTask.priority" @updatePriority="generateData($event, currentTask)" :key="`input-priority-${index}-${keyResultId ? keyResultId : 0}`"/>
        </div>
        <div class="column-input-avatar" v-if="extraInfo.includes('user')">
            <template v-if="extraInfo.includes('key_result')">
                <v-popover :disabled="$resize && $mq.below(780)" offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}" v-if="currentTask.responsible">
                    <img class="avatar" :src="currentTask.responsible.avatar"/>

                    <template slot="popover">
                        <div class="title">{{ currentTask.responsible.name }}</div>
                    </template>
                </v-popover>

                <div class="user-circle" v-else>
                    <icon-user />
                </div>
            </template>
            <InputUserSelect :data="currentTask.responsible ? currentTask.responsible.id : ''" :optionsUsers="$parent.optionsUsers" @updateUser="generateData($event, currentTask)" :key="`input-select-${index}-${keyResultId ? keyResultId : 0}`" v-else/>
        </div>
        <div class="column-icon">
            <div class="icon-box action" @click="openTask(currentTask)" v-if="extraInfo.includes('update') && extraInfo.includes('organigrama')">
                <icon-dots class="rotate-90"/>
            </div>
            <div class="icon-box" v-else-if="extraInfo.includes('key_result')">
                <icon-key-result />
            </div>
        </div>
    </div>
</template>

<script>
import IconTask from '@/components/Icons/Task'
import IconCheck from '@/components/Icons/Check'
import IconRepeat from '@/components/Icons/Repeat'
import IconUser from '@/components/Icons/User'
import IconDots from '@/components/Icons/EditDots'
import IconKeyResult from '@/components/Icons/KeyResult'
import InputCalendarPicker from '@/components/ComponentsTask/CalendarPicker'
import InputPriorityCheck from '@/components/ComponentsTask/PriorityCheck'
import InputUserSelect from '@/components/ComponentsTask/UserSelect'
import InputName from '@/components/ComponentsTask/InputName'
import { debounce } from "debounce";

export default {
    components: {
        IconTask,
        IconCheck,
        IconRepeat,
        IconDots,
        InputCalendarPicker,
        InputPriorityCheck,
        InputUserSelect,
        InputName,
        IconUser,
        IconKeyResult
    },
    props: {
        task: Object,
        index: Number,
        extraInfo: Array,
        fromStep: String,
        keyResultId: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            loaded: false,
            placeholderType: '',
            currentTask: {}
        }
    },
    watch: {
        task: {
            handler: function (val, oldVal) {
                this.currentTask = val;
            },
            deep: true
        }
    },
    created() {
        this.handleDebounceUpdateTask = debounce( (query, formData, taskId) => { this.updateTask(query, formData, taskId); }, 1000);
        this.handleDebounceUpdateTaskObj = debounce( (formData, taskId) => { this.updateTaskObj(formData, taskId); }, 1000);
    },
    mounted () {
        if(this.extraInfo.includes('update')) {
            this.placeholderType = 'Actualizeaza acest task ...';
        }

        this.currentTask = {...this.task};

        if(!this.currentTask.responsible) {
            this.currentTask.responsible = this.$parent.optionsUsers.find(el => el.id === this.currentTask.user_id);
        }

        this.loaded = true;
    },
    methods: {
        toggleCheck(task) {
            if(this.extraInfo.includes('key_result')){
                this.generateFormDataKeyResult({type: 'toggleCheck'}, task);
            } else {
                var newValueDone = task.done === 1 ? 0 : 1;
                task.done = newValueDone;
                var newDate = '';
                
                if(!task.date) {
                    newDate = moment().format('YYYY-MM-DD')
                } else {
                    newDate = moment(task.date).format('YYYY-MM-DD');
                }

                if(task.type == 'date' && task.frequency != null){
                    this.currentTask.deadline = newDate
                } else {
                    this.currentTask.date = newDate
                }
                
                axios.post(`/tasks/${task.task_id ? task.task_id : task.id}/check`, { 
                    date: newDate, 
                    done: newValueDone, 
                    from_meeting_id: this.$parent.$parent.meetingLog.id,
                    from_meeting_step: this.fromStep
                })
            }
        },
        generateData(dataComponent, task) {
            if(this.extraInfo.includes('key_result')) {
                this.generateFormDataKeyResult(dataComponent, task);
            } else {
                this.generateFormDataProject(dataComponent, task);
            }
        },
        generateFormDataKeyResult(dataComponent, task){
            if (dataComponent) {
                switch(dataComponent.type){
                    case "name":
                        this.currentTask.name = dataComponent.value;
                        break;
                    case "date":
                        this.currentTask.deadline = dataComponent.value;
                        break;
                    case "toggleCheck":
                        var newValueDone = task.done === 1 ? 0 : 1;
                        task.done = newValueDone;
                        break;
                }
            }

            var taskFormKeyResult = {
                name: dataComponent.type == 'name' ? dataComponent.value : task.name,
                deadline: dataComponent.type == 'date' ? moment(dataComponent.value).format('YYYY-MM-DD') : moment(task.deadline).format('YYYY-MM-DD'),
                done: task.done,
                from_meeting_id: this.$parent.$parent.meetingLog.id,
                from_meeting_step: this.fromStep
            }

            this.handleDebounceUpdateTaskObj(taskFormKeyResult, task.id);
        },
        generateFormDataProject(dataComponent, task){
            var paramsQuery = {};

            var taskFormObj = {
                name: task.name ? task.name : 'Unamed',
                user_id: task.responsible ? task.responsible.id : (task.user_id ? task.user_id : ''),
                priority: task.priority ? task.priority : '',
                date: task.date,
                from_meeting_id: this.$parent.$parent.meetingLog.id,
                from_meeting_step: this.fromStep
            };

            if(this.extraInfo.includes('project')) {
                taskFormObj.project_id = this.$parent.project.id;
            }

            if( task.frequency && task.type === 'task' ) { 
                paramsQuery.all = 1;
            } else { 
                paramsQuery.current = 1;
            }

            // // if task is log send Id
            // if(task.type == "task_log" ) { 
            //     paramsQuery.task_log_id = task.id;
            // }

            if(dataComponent) { 
                switch(dataComponent.type) {
                    case "responsible":
                        taskFormObj.user_id = dataComponent.value;
                        this.task.responsible = this.$parent.optionsUsers.find(el => el.id == dataComponent.value);
                        break;
                    case "priority":
                        taskFormObj.priority = dataComponent.value;
                        this.task.priority = dataComponent.value;
                        break;
                    case "date":
                        task.date = dataComponent.value;
                        this.task.date = dataComponent.value;
                        break; 
                    case "name":
                        taskFormObj.name = dataComponent.value;
                        this.task.name = dataComponent.value;
                        break; 
                }
            }

            if( task.date ) {
                taskFormObj.deadline = task.date;
                if( !task.frequency ) {
                    taskFormObj.start_date = task.date;
                    taskFormObj.date = task.date;
                }
            }
                
            var parentTaskId = task.task_id;

            this.currentTask = {...this.task};

            this.handleDebounceUpdateTask(paramsQuery, taskFormObj, parentTaskId);
        },
        updateTask(query, formData, taskId) {
            axios.post(`/tasks/${taskId}/update`, formData, { params: query })
            .then(({data}) => {
                if(query.hasOwnProperty('all') && this.task.frequency) {
                    if(this.$parent.$options.name === 'ProjectStep') {
                        const projectFound = this.$parent.project.tasks.find(el => el.id === taskId && el.type === 'task');
                        if(projectFound.task_logs) {
                            projectFound.task_logs.forEach((log) => {
                                Object.assign(log, {...formData});
                            });
                        }
                    }
                }

                // if(data.data.hasOwnProperty('task_log_id') && data.data.task_log_id) {
                //     Object.assign(this.currentTask, { id: data.data.task_log_id, type: 'task_log' });
                // }
            })
        },
        updateTaskObj(formData, taskId) {
            axios.patch(`${process.env.VUE_APP_OBJECTIVE_LINK}/api/tasks/${taskId}`, formData)
            .then(() => {
                this.$parent.$parent.socket.send(JSON.stringify({
                    'entity_id': this.$parent.$parent.meetingLog.id,
                    'entity_user_id': this.$auth.user().id,
                    'type': 'meeting',
                    'custom_data': {
                        'type': 'kr_task',
                        'data': {
                            ...formData,
                            'action': 'update',
                            'task_id': taskId,
                            'key_result_id': this.keyResultId,
                        }
                    }
                }));
            })
        },
        openTask(task){
            this.$root.$emit('open_modal_full_template', 'add_task', {
                crud: 'edit', 
                taskEditId: task.task_id, 
                // type: task.type, 
                date: task.date, 
                from_meeting_id: this.$parent.$parent.meetingLog.id,
                from_meeting_step: this.fromStep
            }, false);
        }
    }
}
</script>